import { useContext, useEffect, useState } from 'react';
import { BackButton, MainButton, useInitData, useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useForm } from 'antd/es/form/Form';
import Spin from 'antd/es/spin';
import Typography from 'antd/es/typography';
import { AccountContext } from './context';
import { AccountForm } from './AccountForm';

const BASE_URL = import.meta.env.VITE_API_BASE_URL;

export function EditAccount() {
  const [initDataUnsafe] = useInitData();
  const webApp = useWebApp();
  const [form] = useForm();
  const account = useContext(AccountContext);
  const [isLoading, setLoading] = useState(false);
  const [canEditCurrency, setCanEditCurrency] = useState(false);
  const [cardsLoaded, setCardsLoaded] = useState(false);
  const [cards, setCards] = useState<string[]>([]);

  useEffect(() => {
    webApp.enableClosingConfirmation();
  }, [webApp]);

  useEffect(() => {
    fetch(`${BASE_URL}/api/web/cards`, {
      method: "POST",
      body: JSON.stringify({
        action: "GET",
        data: initDataUnsafe,
        account_id: account.id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((body) => {
        setCards(body);
        setCardsLoaded(true);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [account.id, initDataUnsafe]);

  useEffect(() => {
    fetch(`${BASE_URL}/api/web/expense`, {
      method: "POST",
      body: JSON.stringify({
        action: "GET",
        data: initDataUnsafe,
        page: 0,
        account_id: account.id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((body) => {
        if (body.length === 0) {
          setCanEditCurrency(true);
        }
      })
      .catch(() => {
      });
  }, [account.id, initDataUnsafe]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const save = async (values: any) => {
    const account_input = {
      currency: values.currency,
      categories: values.categories,
      category_budgets: values.category_budgets,
      cards_alias: values.cards_alias,
      timezone: values.timezone,
    };

    setLoading(true);

    try {
      await fetch(`${BASE_URL}/api/web/account`, {
        method: "POST",
        body: JSON.stringify({
          action: "PUT",
          data: initDataUnsafe,
          account_id: account.id,
          account_input,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      webApp.close();
    } catch (e) {
      alert(e);
    }

    setLoading(false);
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Typography.Title level={3} style={{ flex: 1 }}>Account Settings</Typography.Title>
      </div>
      {!account && !cardsLoaded ? (
        <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin size="large" />
        </div>
      ) : (
        <AccountForm
          form={form}
          canEditCurrency={canEditCurrency}
          account={account}
          save={save}
          cards={cards}
          isLoading={isLoading}
        />
      )}
      <MainButton text="Save" disabled={isLoading} onClick={() => form.submit()} />
      {history.length > 1 && <BackButton onClick={() => window.history.back()} />}
    </div>
  )
}
