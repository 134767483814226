import { useContext, useEffect, useState } from 'react';
import { BackButton, MainButton, useInitData, useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useForm } from 'antd/es/form/Form';
import Spin from 'antd/es/spin';
import Typography from 'antd/es/typography';
import { ExpenseForm } from './ExpenseForm';
import Button from 'antd/es/button';
import { AccountContext } from './context';
import Collapse from 'antd/es/collapse';
import Markdown from 'react-markdown';
import { Dayjs } from 'dayjs';

const BASE_URL = import.meta.env.VITE_API_BASE_URL;

export function Edit({ expenseId }: { expenseId: string }) {
  const [initDataUnsafe] = useInitData();
  const webApp = useWebApp();
  const [form] = useForm();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [expense, setExpense] = useState<any | null>(null);
  const account = useContext(AccountContext);
  const expense_id = expenseId;
  const [isLoading, setLoading] = useState(false);
  const [cardsNo, setCardsNo] = useState<string[]>([]);

  useEffect(() => {
    webApp.enableClosingConfirmation();
  }, [webApp]);

  useEffect(() => {
    (async () => {
      if (!expense_id) {
        return;
      }

      try {
        const expense_response = await fetch(`${BASE_URL}/api/web/expense`, {
          method: "POST",
          body: JSON.stringify({
            action: "GET",
            data: initDataUnsafe,
            account_id: account.id,
            expense_id,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const json = await expense_response.json();

        if (!json) {
          webApp.showAlert("Expense not found. It may have already been deleted.");
          webApp.close();
        } else {
          setExpense(json);
        }
      } catch (e) {
        alert(e);
      }
    })();
  }, [initDataUnsafe, setExpense, account, expense_id, webApp]);

  useEffect(() => {
    fetch(`${BASE_URL}/api/web/cards`, {
      method: "POST",
      body: JSON.stringify({
        action: "GET",
        data: initDataUnsafe,
        account_id: account.id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((body) => {
        setCardsNo(body);
      })
      .catch(() => {
      });
  }, [account.id, initDataUnsafe]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const save = async (values: any) => {
    const time: Dayjs = values.time;
    const date = (values.date as Dayjs)
      .set("hours", time.hour())
      .set("minutes", time.minute())
      .set("seconds", time.second())
      .set("milliseconds", time.millisecond())
      .format(); 
    const expense_input = {
      ...values,
      id: expense_id,
      date,
    };

    setLoading(true);

    try {
      await fetch(`${BASE_URL}/api/web/expense`, {
        method: "POST",
        body: JSON.stringify({
          action: "PUT",
          data: initDataUnsafe,
          account_id: account.id,
          expense_input,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      webApp.close();
    } catch (e) {
      alert(e);
    }

    setLoading(false);
  };

  const confirmDeleteExpense = () => {
    webApp.showConfirm("Are you sure you want to delete this expense?", async (confirm: boolean) => {
      if (!confirm) {
        return;
      }

      setLoading(true);

      try {
        await fetch(`${BASE_URL}/api/web/expense`, {
          method: "POST",
          body: JSON.stringify({
            action: "DELETE",
            data: initDataUnsafe,
            account_id: account.id,
            expense_id,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (history.length > 1) {
          window.history.back();
        } else {
          webApp.close();
        }
      } catch (e) {
        alert(e);
      }

      setLoading(false);
    });
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Typography.Title level={3} style={{ flex: 1 }}>Edit Expense</Typography.Title>
        {/* <Button onClick={() => {}} size="large">Duplicate</Button> */}
        {expense && <Button onClick={() => confirmDeleteExpense()} danger={true} size="large">Delete Expense</Button>}
      </div>
      {!expense ? (
        <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <ExpenseForm
            form={form}
            cardsNo={cardsNo}
            cardsAlias={account.cardsAlias ?? []}
            expense={expense}
            categories={account.categories ?? []}
            defaultCurrency={account.currency}
            save={save}
            isLoading={isLoading}
          />
          {expense.markdown ? (
            <Collapse
              items={[{ 
                key: '1',
                label: 'Receipt',
                children: (
                  <Markdown
                    components={{
                      p({ className, children, ...props }) {
                        return <p className={className} {...props} style={{ whiteSpace: 'pre-wrap' }}>{children}</p>
                      }
                    }}>
                    {expense.markdown}
                  </Markdown>
                ),

              }]}
            />
          ) : null }
        </>
      )}
      <MainButton text="Save" disabled={isLoading} onClick={() => form.submit()} />
      {history.length > 1 && <BackButton onClick={() => window.history.back()} />}
    </div>
  )
}
